<template>
    <Layout ref="layout">
        <ModalCustomAppearance/>
        <ModalCustomInfo/>
        <div v-if="isSuspend != true" class="setting-plan-container">
            <div class="col-md-6">
                <p class="setting-plan-title">Enterprise profile</p>
                <form action="" @submit.prevent="submit()">
                    <div class="settings-name mb-3">
                        <label for="first-name-profile" class="">Compay full name</label>
                        <div class="input-state-profile">
                            <input
                                @focus="focusCompanyFullName()"
                                @focusout="focusoutCompanyFullName()"
                                v-model.trim="$v.newCompanyFullName.$model"
                                placeholder="Your company full name"
                                type="text"
                                id="first-name-profile"
                                :class="{'is-invalid': validationStatus($v.newCompanyFullName)}"
                                class="form-control input-profile-v3">
                            <div class="invalid-feedback">
                                <span v-if="!$v.newCompanyFullName.maxLength" id="first-name-error" class="required-color error-message-v3">Full name company max. length is 64 characters.</span>
                                <span v-if="!$v.newCompanyFullName.required" id="first-name-empty" class="required-color error-message-v3">Please enter a company full name.</span>
                            </div>
                        </div>
                    </div>
                    <div class="settings-name mb-3">
                        <div class="d-flex align-items-start">
                            <label for="last-name-profile" class="">Company short name</label>
                            <img
                                src="@/assets/images/icon/info-grey.svg"
                                class="mt-1 ml-2 cursor-pointer"
                                style="height: 16px; width: 16px"
                                data-toggle="tooltip"
                                title=" An abbreviation of the company full name to be displayed as an enterprise account button on Jaybod screen (e.g. JAYBOD for Jaybod enterprise)"
                                alt="">
                        </div>
                        <div class="input-state-profile">
                            <input
                                @focus="focusCompanyShortName()"
                                @focusout="focusoutCompanyShortName()"
                                v-model.trim="$v.newCompanyShortName.$model"
                                @keydown="preventSpace($event)"
                                placeholder="Your company short name"
                                type="text"
                                id="last-name-profile"
                                :class="{'is-invalid': validationStatus($v.newCompanyShortName)}"
                                class="form-control input-profile-v3">
                            <div class="invalid-feedback">
                                <span v-if="!$v.newCompanyShortName.maxLength" id="last-name-error" class="required-color error-message-v3">Short name company max. length is  6  characters.</span>
                                <span v-if="!$v.newCompanyShortName.required" id="last-name-empty" class="required-color error-message-v3">Please enter a company short name.</span>
                            </div>
                        </div>
                    </div>
                    <div class="settings-customization mb-3">
                        <label for="subdomain" class="">Subdomain</label>
                        <div class="d-flex justify-content-start input-custom-enterprise w-312">
                            <div class="input-custom-enterprise">
                                <input
                                    @focus="focusSubdomain()"
                                    @focusout="focusoutSubdomain()"
                                    v-model.trim="$v.newSubdomain.$model"
                                    @keydown="preventSpace($event)"
                                    @keyup="checkedSubdomain"
                                    placeholder="Your subdomain"
                                    type="text"
                                    id="subdomain"
                                    :class="{'is-invalid': validationStatus($v.newSubdomain) || isSubdomainExist}"
                                    class="w-170">
                                <div class="invalid-feedback">
                                    <span v-if="!$v.newSubdomain.isValid" id="first-name-error" class="required-color error-message-v3">Letters, numbers, and “-” only.</span>
                                    <span v-else-if="!$v.newSubdomain.required" id="first-name-empty" class="required-color error-message-v3">Please enter a subdomain.</span>
                                    <span v-else-if="!$v.newSubdomain.maxLength" id="first-name-empty" class="required-color error-message-v3">Max. length is 255 characters.</span>
                                    <span v-else-if="isSubdomainExist == true" id="first-name-empty" class="required-color error-message-v3">{{textSubdomain}}</span>
                                </div>
                            </div>
                            <span class="value-size-form ml-3">.jaybod.com</span>
                        </div>
                    </div>
                    <div class="settings-customization mb-3">
                        <div class="d-flex align-items-start">
                            <label for="email-profile" class="">Default storage per member</label>
                            <img
                                src="@/assets/images/icon/info-grey.svg"
                                class="mt-1 ml-2 cursor-pointer"
                                style="height: 16px; width: 16px"
                                data-toggle="tooltip"
                                title="The default storage size for every seat in this enterprise. The storage size can be changed when adding a new seat to the enterprise."
                                alt="">
                        </div>
                        <div class="d-flex justify-content-end">
                            <div class="input-custom-enterprise">
                                <input
                                    @focus="focusGivenSize()"
                                    @focusout="focusoutGivenSize()"
                                    v-model.trim="$v.newGivenSize.$model"
                                    placeholder="Default size storage"
                                    type="number"
                                    id="email-profile"
                                    :class="{'is-invalid': validationStatus($v.newGivenSize)}"
                                    class="w-240">
                                <div class="invalid-feedback">
                                    <span v-if="!$v.newGivenSize.isZero" id="first-name-error" class="required-color error-message-v3">Please enter a number greater than 0.</span>
                                    <span v-if="!$v.newGivenSize.required" id="first-name-empty" class="required-color error-message-v3">Please enter default size storage.</span>
                                </div>
                            </div>
                            <span class="value-size-form ml-3">GB</span>
                        </div>
                    </div>
                </form>
                <div class="settings-name-btn mb-3">
                    <div class="w-312">
                        <button class="btn-submit-settings-plan"
                            @click="submit()"
                            :disabled="$v.$invalid || 
                            (isCompayFullNameFocus == false &&
                            isCompayShortNameFocus == false &&
                            isSubdomainFocus == false &&
                            isGivenSizeFocus == false) &&
                            newCompayFullName != '' &&
                            newCompayShortName != '' &&
                            newDomain != '' &&
                            newGivenSize != '' &&
                            newCompanyFullName == myEnterprise.company_name &&
                            newCompanyShortName == myEnterprise.short_name &&
                            newSubdomain == myEnterprise.subdomain &&
                            newGivenSize == myEnterprise.min_user_storage ||
                            isSubdomainExist == true
                            ">Save</button>
                    </div>
                </div>
                <hr class="horizontal-account-profile">
                <p class="setting-plan-title">Enterprise appearance</p>
                <div class="settings-name mb-3">
                    <div class="d-flex">
                        <p class="title-modal-change-password">Logo and colors</p>
                        <img
                            src="@/assets/images/icon/info-grey.svg"
                            class="mt-1 ml-2 cursor-pointer"
                            style="height: 16px; width: 16px"
                            data-toggle="tooltip"
                            title="Customize the appearance of the enterprise page including the logo, main color, and accent color."
                            alt="">
                    </div>
                    <div class="w-312">
                        <button
                            @click="openCustomAppearance()"
                            class="btn-submit-settings-plan">
                                Customize Design
                        </button>
                    </div>
                    <!-- <pre>{{ myEnterprise }}</pre> -->
                </div>
            </div>
        </div>
        <SuspendPage v-if="isSuspend == true" />
    </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import ModalCustomAppearance from "./modalCustomAppearance.vue"
import ModalCustomInfo from "./modalCustomizeInfo.vue"
import SuspendPage from "./suspendEnterprise.vue"
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
    data(){
        return {
            newCompanyFullName: '',
            newCompanyShortName: '',
            newSubdomain: '',
            newGivenSize: '',
            isCompanyFullNameFocus: false,
            isCompanyShortNameFocus: false,
            isSubdomainFocus: false,
            isGivenSizeFocus: false,
            isSubdomainExist: false,
            textSubdomain: ''
        }
    },
    components: {
        Layout,
        ModalCustomAppearance,
        ModalCustomInfo,
        SuspendPage
    },
    validations: {
        newCompanyFullName: {
            required,
            maxLength: maxLength(64)
        },
        newCompanyShortName: {
            required,
            maxLength: maxLength(6)
        },
        newSubdomain: {
            required,
            maxLength: maxLength(255),
            isValid (value) {
                if (value === '') return true
                var subdomain_regex = /^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/;
                // var subdomain_regex = /^((?!-)[A-Za-z0-9-]{1,}(?<!-)\.)+[A-Za-z]{2,6}$/;
                // var finalValue = value + ".jaybod.com"
                return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(subdomain_regex.test(value))
                    // resolve(subdomain_regex.test(finalValue))
                }, 350 + Math.random()*300)
                })
            }
        },
        newGivenSize: {
            required,
            isZero(value) {
                if (value === '') return true
                var num_regex = /^[1-9][0-9]*$/;
                return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(num_regex.test(value))
                }, 350 + Math.random()*300)
                })
            }
        },
    },
    mounted() {
        if(this.$store.getters['state/state'] != 'enterprise'){
            this.$router.push({name:'home'}).catch({})
        } else {
            if(this.myEnterprise == null){
                    this.$store.dispatch('enterprise/getMyEnterprise')
            }
            if(sessionStorage.getItem('newAdminEnterprise')){
                this.$bvModal.show('modal-customize-enterprise-info')
            }
            this.initFormValue()
        }
    },
    computed: {
        isSuspend(){
            return this.$store.getters['enterprise/statusSuspend']
        },
        myEnterprise(){
            return this.$store.getters['enterprise/myEnterprise']
        },
        status2fa(){
            return this.$store.getters['user/enabled2fa']
        },
        firstname(){
            return this.$store.getters['user/firstName'];
        },
        lastname(){
            return this.$store.getters['user/lastName'];
        },
        loading(){
            return this.$store.getters['user/loading'];
        },
        nama() {
            return this.$store.getters['user/firstName']
        },
        email() {
            return this.$store.getters['user/email']
        },
        message() {
            return this.$store.getters['error/message']
        },
        success() {
            return this.$store.getters['error/success']
        },
        error() {
            return this.$store.getters['error/error']
        }
    },
    methods: {
        checkedSubdomain() {
            var payload = {
                subdomain: this.newSubdomain
            }
            if(this.newSubdomain != this.myEnterprise.subdomain){
                this.$store.dispatch('enterprise/checkSubdomain', payload)
                .then((res) =>{
                    this.isSubdomainExist = false
                    this.textSubdomain = ''
                })
                .catch((err) =>{
                    this.isSubdomainExist = true
                    this.textSubdomain = err
                })
            }
        },
        convertByteToGB(byte) {
            var gigabyte = byte / (1024 * 1024 * 1024);
            return gigabyte.toFixed(0);
        },
        convertGBToByte(gigabytes) {
            var byte = gigabytes * Math.pow(1024, 3);
            return byte.toFixed(0);
        },
        initFormValue(){
            this.newCompanyFullName = this.myEnterprise.company_name
            this.newCompanyShortName = this.myEnterprise.short_name
            this.newSubdomain = this.myEnterprise.subdomain
            this.newGivenSize = this.convertByteToGB(this.myEnterprise.min_user_storage)
        },
        preventSpace(e){
            if(e.keyCode == 32) {
                e.preventDefault()
            }
        },
        submit(){
            var payload = {
                enterprise: this.myEnterprise._id,
                company_name: this.newCompanyFullName, //optional
                short_name: this.newCompanyShortName, //optional
                min_user_storage: this.convertGBToByte(this.newGivenSize) //optional
            }
            if(this.newSubdomain != this.myEnterprise.subdomain){
                payload.subdomain = this.newSubdomain
            }
            this.$store.dispatch('enterprise/changeSetting', payload)
            .then(()=>{
                this.$toasted.show('Enterprise profile saved successfully.',{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000
                })
            })
        },
        openCustomAppearance() {
            this.$bvModal.show('modal-custom-appearance-enterprise')
        },
        validationStatus(validation) {
            return typeof validation != undefined ? validation.$error: false
        },
        focusCompanyFullName(){
            this.isCompanyFullNameFocus = true
        },
        focusoutCompanyFullName(){
            this.isCompanyFullNameFocus = false
        },
        focusCompanyShortName(){
            this.isCompanyShortNameFocus = true
        },
        focusoutCompanyShortName(){
            this.isCompanyShortNameFocus = false
        },
        focusSubdomain(){
            this.isSubdomainFocus = true
        },
        focusoutSubdomain(){
            this.isSubdomainFocus = false
        },
        focusGivenSize(){
            this.isGivenSizeFocus = true
        },
        focusoutGivenSize(){
            this.isGivenSizeFocus = false
        }
    }
}
</script>

<style>

</style>