<template>
    <b-modal id="modal-custom-appearance-enterprise" no-close-on-backdrop hide-header hide-footer centered>
        <div class="d-flex flex-column">
            <span class="enterprise-ca-title">Customize appearance</span>
            <span class="enterprise-ca-desc my-3">Customize the logo, main color, and accent color for the enterprise's appearance.</span>
            <div class="row row-ca">
                <div class="col-md-6">
                    <div class="mt-3 d-flex align-items-center">
                        <span class="enterprise-ca-text"><span class="mr-2">1.</span>Logo</span>
                        <img
                            src="@/assets/images/icon/info-grey.svg"
                            class="cursor-pointer ml-2"
                            style="height: 16px; width: 16px"
                            data-toggle="tooltip"
                            title="Recommend to use an image with maximal size of 180x25 and a PNG file extension."
                            alt="">
                            <!-- {{imgPreview}} -->
                    </div>
                    <div class="d-flex justify-content-start align-items-center my-3">
                        <button @click="openImgUpload()" class="btn-upload-img-enterprise">Upload Image</button>
                        <input
                            id="uploadImgEnterprise"
                            type="file"
                            accept="image/png"
                            @change="previewImg"
                            style="visibility: hidden; display: none;">
                        <div @click="openImgUpload()" v-if="imgPreview" id="previewLogoEnterprise" class="cursor-pointer ml-3">
                            <img :src="imgPreview" alt="" style="height: 32px; width: 120px;">
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mt-3 d-flex align-items-center">
                        <span class="enterprise-ca-text"><span class="mr-2">2.</span>Favicon</span>
                        <img
                            src="@/assets/images/icon/info-grey.svg"
                            class="cursor-pointer ml-2"
                            style="height: 16px; width: 16px"
                            data-toggle="tooltip"
                            title="Recommend to use an image with maximal size of 32x32 and an ICO or PNG file extension."
                            alt="">
                            <!-- {{imgPreview}} -->
                    </div>
                    <div class="d-flex justify-content-start align-items-center my-3">
                        <button @click="openImgFavUpload()" class="btn-upload-img-enterprise">Upload Image</button>
                        <input
                            id="uploadImgFavEnterprise"
                            type="file"
                            accept="image/png, image/x-icon"
                            @change="previewFavImg"
                            style="visibility: hidden; display: none;">
                        <div @click="openImgFavUpload()" v-if="imgFavPreview" id="previewLogoFavEnterprise" class="cursor-pointer ml-3">
                            <img :src="imgFavPreview" alt="" style="height: 32px; width: 32px;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-ca my-3">
                <div class="col-md-6">
                    <div class="mb-3 d-flex align-items-center">
                        <span class="enterprise-ca-text"><span class="mr-2">3.</span>Main color</span>
                        <img
                            src="@/assets/images/icon/info-grey.svg"
                            class="cursor-pointer ml-2"
                            style="height: 16px; width: 16px"
                            data-toggle="tooltip"
                            title="Main color is the color used most frequently on screens, such as for button, button text, icon, and progress bar."
                            alt="">
                    </div>
                    <div class="container-custom-input-color">
                        <label for="custom-main-color">Choose Color</label>
                        <input
                            id="custom-main-color"
                            v-model="mainColor"
                            type="color">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3 d-flex align-items-center">
                        <span class="enterprise-ca-text"><span class="mr-2">4.</span>Accent color</span>
                        <img
                            src="@/assets/images/icon/info-grey.svg"
                            class="cursor-pointer ml-2"
                            style="height: 16px; width: 16px"
                            data-toggle="tooltip"
                            title="Accent color is the complement for the main color."
                            alt="">
                    </div>
                    <div class="container-custom-input-color">
                        <label for="custom-second-color">Choose Color</label>
                        <input
                            id="custom-second-color"
                            v-model="accentColor"
                            type="color">
                    </div>
                </div>
            </div>
            <div class="my-3">
                <img src="@/assets/images/example-color-enterprise.svg" alt="">
            </div>
            <div class="d-flex justify-content-end align-items-center mt-3 close-and-submit-enterprise">
                <span class="mr-3" @click="close()">Cancel</span>
                <button
                @click="submit()"
                class="ml-3"
                >Save</button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    data() {
        return {
            imgPreview: null,
            imgFavPreview: null,
            accentColor: null,
            mainColor: null,
            sendImg: null,
            sendFavImg: null
        }
    },
    mounted(){
        this.reset() 
    },
    computed: {
        myEnterprise(){
            return this.$store.getters['enterprise/myEnterprise']
        },
    },
    methods: {
        reset(){
            this.imgPreview = this.myEnterprise.logo
            this.imgFavPreview = this.myEnterprise.favicon
            this.mainColor = this.myEnterprise.main_color
            this.accentColor = this.myEnterprise.accent_color
        },
        openImgUpload(){
            document.getElementById("uploadImgEnterprise").click()
        },
        openImgFavUpload(){
            document.getElementById("uploadImgFavEnterprise").click()
        },
        previewImg(e){
            const file = e.target.files[0]
            var payloadImg = {
                enterprise: this.myEnterprise._id,
                file_name: file.name,
                content_type: file.type
            }
            var payload = {
                payloadImg: payloadImg,
                file: file
            }
            this.$store.dispatch('enterprise/getPreviewLogo', payload)
            .then((res)=> {
                // this.imgPreview = res
                this.sendImg = res
            })
            .catch((err)=>{
            })
            const reader = new FileReader()
            reader.onload = (e) => {
                this.imgPreview = e.target.result
            }
            reader.readAsDataURL(file)
        },
        previewFavImg(e){
            const file = e.target.files[0]
            var payloadImg = {
                enterprise: this.myEnterprise._id,
                file_name: file.name,
                content_type: file.type
            }
            var payloadFav = {
                payloadImg: payloadImg,
                file: file
            }
            this.$store.dispatch('enterprise/getPreviewLogo', payloadFav)
            .then((res)=> {
                // this.imgPreview = res
                this.sendFavImg = res
            })
            .catch((err)=>{
            })
            const reader = new FileReader()
            reader.onload = (e) => {
                this.imgFavPreview = e.target.result
            }
            reader.readAsDataURL(file)
        },
        close() {
            this.reset()
            this.$bvModal.hide('modal-custom-appearance-enterprise')
        },
        submit() {
            var payload = {
                enterprise: this.myEnterprise._id,
                logo: this.sendImg, //optional
                main_color: this.mainColor, //optional
                accent_color: this.accentColor, //optional
                favicon: this.sendFavImg
            }
            this.$store.dispatch('enterprise/changeSetting', payload)
            .then(()=>{
                this.$bvModal.hide('modal-custom-appearance-enterprise')
                this.$toasted.show(`Enterprise appearance saved successfully.`,{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000,
                    containerClass : 'toast-snackbar'
                })
            })
        },
    }
}
</script>

<style>

</style>