<template>
    <b-modal id="modal-customize-enterprise-info" no-close-on-backdrop hide-header hide-footer centered>
        <div class="d-flex flex-column">
            <div class="mb-4">
                <img src="@/assets/images/custom-enterprise-popup.svg" alt="">
            </div>
            <span class="mb-3 enterprise-ca-text">Customize your enterprise page</span>
            <span class="mb-3 enterprise-ca-desc">Customization allows you to have a personalized enterprise page that reflects your brand identity.</span>
            <span class="mb-4 enterprise-ca-desc">All the details are already filled in, but you can edit them, also add a logo and choose the colors.</span>
            <div class="d-flex justify-content-end align-items-center">
                <button @click="close()" class="btn-custom-enterprise-info">Got it</button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    methods: {
        close() {
            this.$bvModal.hide('modal-customize-enterprise-info')
            sessionStorage.removeItem('newAdminEnterprise')
        }
    }
}
</script>

<style>

</style>